<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">账号管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">账号列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{ title }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box">
              <el-form ref="ruleForm" label-width="7rem" class="form" :rules="rules" :model="ruleForm">
                <el-form-item label="账号名称" prop="adminName">
                  <el-input v-model="ruleForm.adminName"></el-input>
                </el-form-item>
                <el-form-item style="margin-bottom: 5px" class="password">
                  <div>
                    <span>默认密码为Aa123456</span>
                  </div>
                </el-form-item>
                <el-form-item label="使用人姓名" prop="fullname">
                  <el-input v-model="ruleForm.fullname"></el-input>
                </el-form-item>
                <el-form-item label="使用人电话" prop="mobile">
                  <el-input v-model="ruleForm.mobile"></el-input>
                </el-form-item>
                <el-form-item label="角色" prop="realRoleId" class="liHeight">
                  <el-select v-model="ruleForm.realRoleId" placeholder="请选择" @change="roleChange" filterable clearable>
                    <el-option size="small" v-for="item in rolelist" :key="item.id" :label="item.name" :value="item.id">
                      <div style="display: flex;    justify-content: space-between;">
                        <span>{{ item.name }}</span>
                        <span style="color: #8492a6; font-size: 13px">备注:{{ item.description || '无' }}</span>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="角色类型" v-if="ruleForm.roleId">
                  <el-select disabled v-model="ruleForm.roleId" clearable>
                    <el-option size="small" v-for="item in roleType" :key="item.id" :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="归属公司" v-if="ruleForm.roleId && ruleForm.roleId == '-1'">
                  <el-select v-model="ruleForm.specialCondition" clearable>
                    <el-option size="small" v-for="item in specialConditionList" :key="item.id" :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="安全权限" prop="desensitization">
                  <el-checkbox v-model="ruleForm.adminSensitive" @change="checkboxchangeIdcard">身份证脱敏</el-checkbox>
                  <el-checkbox v-model="ruleForm.mobileSensitive" @change="checkboxchangeMobile">手机号脱敏</el-checkbox>
                  <el-checkbox v-model="ruleForm.videoIsMark" @change="checkboxchangeWatermark">视频预览水印</el-checkbox>
                </el-form-item>
                <el-form-item label="帐号类型" prop="tempAccount" v-if="ruleForm.roleId != -6">
                  <el-radio v-model="ruleForm.tempAccount" :label="false">正式用户</el-radio>
                  <el-radio v-model="ruleForm.tempAccount" :label="true">临时用户</el-radio>
                </el-form-item>
                <el-form-item label="有效期限" prop="accountExpireDate" v-if="ruleForm.tempAccount == true">
                  <el-date-picker v-model="ruleForm.accountExpireDate" type="date" format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="上传文件" v-if="ruleForm.roleId != '-10'">
                  <el-upload class="upload-btndrag" :action="actionUrl" :on-error="handleError"
                    :on-success="handleSuccess" :on-change="uploadChange1" :show-file-list="false" :auto-upload="false"
                    multiple drag>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或<em>点击上传</em>
                    </div>
                  </el-upload>
                  <div v-if="ruleForm.fileList.length" style="margin-top: 10px; margin-left: 10px">
                    当前选择文件：
                    <div style="display: flex; flex-direction: column">
                      <span style="color: #409eff" v-for="(item, index) in ruleForm.fileList" :key="index">
                        {{ item.fileName }}
                        <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px">预览</a>
                        <a @click="reomveExlUpload(index)" style="color: red; margin: 0 15px">删除</a>
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <p style="margin-top: 10px; margin-left: 10px">
                      未选择文件
                    </p>
                  </div>
                  <!-- <el-upload
                  :on-change="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                  :disabled="disabled"
                >
                  <el-image
                    :src="Form.thumbnail || require('@/assets/develop.png')"
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
                <p style="color:#f46173">
                  请上传640*375（长*宽）像素的PNG、JPG格式的图片，且文件大小不超过1MB！
                </p> -->
                </el-form-item>
              </el-form>
              <el-form ref="ruleForm1" label-width="7rem" class="form" :rules="rules1" :model="ruleForm1"
                v-if="ruleForm.roleId == 2">
                <el-form-item label="行政区划" prop="cityId">
                  <el-cascader :props="{ emitPath: false, checkStrictly: true }" v-model="ruleForm1.cityId"
                    :options="cityTree" @change="handleCascader" clearable></el-cascader>
                </el-form-item>
                <el-form-item label="监管类型" prop="trainTypeId">
                  <el-cascader :props="{
                    emitPath: false,
                    value: 'id',
                    label: 'label',
                    children: 'children',
                    checkStrictly: true,
                  }" v-model="ruleForm1.trainTypeId" :options="trainTypeList" @change="handleTrainType"
                    clearable></el-cascader>
                </el-form-item>
                <el-form-item label="数据范围" prop="superviseDate">
                  <el-date-picker style="width: 400px!important;" v-model="ruleForm1.superviseDate" clearable
                    type="daterange" range-separator="至" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-form>
              <el-form ref="ruleForm2" label-width="7rem" class="form" :rules="rules2" :model="ruleForm2"
                v-if="['3','-6','-10'].includes(ruleForm.roleId)">
                <el-form-item label="关联单位" prop="compId">
                  <el-select clearable v-model="ruleForm2.compId" filterable remote :remote-method="searchCopmp"
                    placeholder="请至少输入两个字搜索">
                    <el-option size="small" v-for="item in compList" :key="item.compId" :label="item.compName"
                      :value="item.compId"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="账号权限" prop="permissionsId" v-if="ruleForm.roleId != -6">
                  <el-select clearable v-model="ruleForm2.permissionsId" placeholder="请选择账号权限">
                    <el-option size="small" v-for="item in permissionsList1" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="数据类型" prop="trainTypeIds" v-if="ruleForm.roleId != '-10'">
                  <el-cascader placeholder="请选择数据类型" v-model="ruleForm2.trainTypeIds" :options="trainTypeList2"
                    :props="propsTrainType" @change="trainTypeListChange" size="small"></el-cascader>
                </el-form-item>
              </el-form>
              <el-form ref="ruleForm3" label-width="7rem" class="form" :rules="rules3" :model="ruleForm3"
                v-show="ruleForm.roleId == -2">
                <el-form-item label="关联课程方" prop="compId">
                  <el-select clearable v-model="ruleForm3.compId" filterable remote :remote-method="searchCourseUser"
                    placeholder="请输入关键字搜索">
                    <el-option size="small" v-for="item in courseUserList" :key="item.courseOwnerId" :label="item.name"
                      :value="item.courseOwnerId"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-form ref="ruleForm4" label-width="7rem" class="form" :rules="rules4" :model="ruleForm4"
                v-show="ruleForm.roleId == -3 || ruleForm.roleId == -5">
                <el-form-item label="关联代理商" prop="agencyId">
                  <el-select clearable v-model="ruleForm4.agencyId" filterable remote :remote-method="searchAgency"
                    placeholder="请输入关键字搜索" @change="agencyChange">
                    <el-option size="small" v-for="item in AgencyList" :key="item.agencyId" :label="item.agencyType && item.agencyType != '20'
                        ? item.agencyName + '(' + item.agencyMobile + ')'
                        : item.agencyName
                      " :value="item.agencyId"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="账号权限" prop="permissionsId">
                  <el-select clearable v-model="ruleForm4.permissionsId" @change="permissionsChange"
                    placeholder="请选择账号权限">
                    <el-option size="small" v-for="item in permissionsList" :key="item.value" :label="item.label"
                      :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="数据类型" prop="trainTypeId">
                  <el-cascader
                    placeholder="请选择数据类型"
                    v-model="ruleForm4.trainTypeId"
                    :options="trainTypeList1"
                    :props="propsTrainType"
                    size="small"
                  ></el-cascader>
                </el-form-item> -->
                <el-form-item label="创建账号" prop="allowNew" v-if="ruleForm.roleId == -3">
                  <el-checkbox v-model="ruleForm4.allowNew" @change="checkboxchangeallowNew">代理商允许新建账号</el-checkbox>
                </el-form-item>
              </el-form>
              <el-form ref="ruleForm5" label-width="7rem" class="form" :rules="rules5" :model="ruleForm5"
                v-if="ruleForm.roleId == -4">
                <el-form-item label="行政区划" prop="cityId">
                  <el-cascader :props="{ emitPath: false, checkStrictly: true }" v-model="ruleForm5.cityId"
                    :options="cityTree" @change="handleCascader" clearable></el-cascader>
                </el-form-item>
              </el-form>
              <el-form ref="ruleForm6" label-width="7rem" class="form" :rules="rules6" :model="ruleForm6"
                v-if="ruleForm.roleId == -8">
                <el-form-item label="实习学校" prop="schoolId">
                  <el-select clearable v-model="ruleForm6.schoolId" filterable remote :remote-method="getSchoolList"
                    placeholder="请输入关键字搜索">
                    <el-option size="small" v-for="item in schoolListData" :key="item.schoolId" :label="item.schoolName"
                      :value="item.schoolId"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <el-form ref="ruleForm7" label-width="7rem" class="form" :rules="rules7" :model="ruleForm7"
                v-if="ruleForm.roleId == -9">
                <el-form-item label="关联单位" prop="compId">
                  <el-select clearable v-model="ruleForm7.compId" filterable remote :remote-method="searchCopmp"
                    placeholder="请至少输入两个字搜索">
                    <el-option size="small" v-for="item in compList" :key="item.compId" :label="item.compName"
                      :value="item.compId"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="btn-box flexcc">
              <el-button @click="dohandleOk" class="bgc-bv">取 消</el-button>
              <el-button v-if="stu === 'add'" class="bgc-bv" @click="doAddSave">确 定</el-button>
              <el-button v-else class="bgc-bv" @click="doEditSave">确 定</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 预览附件 - loading -->
    <el-dialog title="预览" :visible.sync="previewLoding1" @close="uploadLodClose" width="50%" top="2%" center>
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px" v-if="fileType == 'pdf' || fileType == 'PDF'"></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "pdfobject";
export default {
  name: "operate/accountEdit",
  components: {},
  data() {
    return {
      title: "创建账号",
      stu: "add",
      id: "",
      // 基本信息
      ruleForm: {
        adminName: "", // 角色名称
        fullname: "", // 使用人姓名
        mobile: "", // 使用人电话
        realRoleId: "", // 角色id
        roleId: "", // 角色类型下拉选回显
        adminSensitive: true, // 身份证脱敏
        mobileSensitive: true, //手机号脱敏
        videoIsMark: true, //视频预览水印
        specialCondition: "", // 归属公司
        tempAccount: false, //用户类型
        accountExpireDate: "", //结束日期
        fileList: [], //文件
      },
      ruleForm1: {
        cityId: "", // 行政区划id
        trainTypeId: "", // 培训类型
        superviseDate: [],//数据范围
      },
      ruleForm2: {
        compId: "", // 单位
        trainTypeIds: [],
        permissionsId: "",
      },
      ruleForm3: {
        compId: "", // 课程方
      },
      ruleForm4: {
        agencyId: "", // 代理商
        trainTypeId: [],
        permissionsId: "",
        allowNew: false,
      },
      ruleForm5: {
        cityId: "", // 行政区划id
      },
      ruleForm6: {
        schoolId: "", // 学校id
      },
      ruleForm7: {
        compId: "", // 企业id
      },
      // 实习学校下拉数据
      schoolListData: [],
      roleType: [], // 角色类型列表
      rules: {
        adminName: [
          { required: true, trigger: "blur", message: "账号名称不能为空" },
          { trigger: "blur", max: 20, message: "账号名称不超过20个字" },
        ],
        fullname: [
          { required: true, trigger: "blur", message: "使用人姓名不能为空" },
          { trigger: "blur", max: 20, message: "使用人姓名不超过20个字" },
        ],
        realRoleId: [
          { required: true, trigger: "change", message: "请选择角色" },
        ],
        mobile: [
          {
            required: true,
            validator: this.$validatePhone,
            trigger: "blur",
          },
        ],
        tempAccount: [
          { required: true, trigger: "change", message: "请选择帐号类型" },
        ],
        accountExpireDate: [
          { required: true, trigger: "change", message: "请选择有效期" },
        ],
      },
      rules1: {
        cityId: [
          { required: true, trigger: "change", message: "行政区划不能为空" },
        ],
        trainTypeId: [
          { required: true, trigger: "change", message: "培训类型不能为空" },
        ],
      },
      rules2: {
        compId: [
          { required: true, trigger: "change", message: "关联单位不能为空" },
        ],
        permissionsId: [
          { required: true, trigger: "change", message: "账号权限不能为空" },
        ],
        trainTypeIds: [
          { required: true, trigger: "change", message: "数据类型不能为空" },
        ],
      },
      rules3: {
        compId: [
          { required: true, trigger: "change", message: "课程方不能为空" },
        ],
      },
      rules4: {
        agencyId: [
          { required: true, trigger: "change", message: "代理商不能为空" },
        ],
        permissionsId: [
          { required: true, trigger: "change", message: "账号权限不能为空" },
        ],
        trainTypeId: [
          { required: true, trigger: "change", message: "培训类型不能为空" },
        ],
      },
      rules5: {
        cityId: [
          { required: true, trigger: "change", message: "行政区划不能为空" },
        ],
      },
      rules6: {
        schoolId: [
          { required: true, trigger: "change", message: "关联学校不能为空" },
        ],
      },

      rules7: {
        compId: [
          { required: true, trigger: "change", message: "关联单位不能为空" },
        ],
      },
      courseUserList: [], // 课程方
      cityTree: [], // 行政区划
      rolelist: [], // 角色列表
      trainTypeList: [], // 培训类型
      compList: [], // 单位
      parentName: "", //
      specialConditionList: [], // 归属公司
      AgencyList: [], // 代理商
      permissionsList: [
        {
          value: 10,
          label: "仅本人",
        },
        {
          value: 20,
          label: "仅本公司",
        },
        {
          value: 40,
          label: "本人及所建账号",
        },
      ],
      permissionsList1: [
        {
          value: 10,
          label: "仅本人",
        },
        {
          value: 20,
          label: "仅本公司",
        },
        {
          value: 30,
          label: "所有公司(包含子单位)",
        },
      ],
      baseTrainType: [],
      enterpriseTrainType: [],
      // trainTypeList1: [],
      trainTypeList2: [],
      propsTrainType: {
        value: "id",
        label: "label",
        emitPath: false,
        checkStrictly: true,
        multiple: true,
        expandTrigger: 'hover',
      },
      oldRoleId: "",
      previewLoding1: false,
      fileType: "",
      ImgSrc: "",
    };
  },
  created() {
    this.stu = this.$route.query.stu;
    this.$nextTick(async () => {
      await this.init();
      if (this.stu == "edit") {
        this.id = this.$route.query.id;
        this.title = "编辑账号";
        this.getDetail(this.id);
      }
    })
  },
  computed: {},
  methods: {
    init() {
      this.getcity(); // 行政区划
      this.getrole(this.$route.query.roleId); // 基础角色类型
      this.getTraintype(); // 培训
      this.getspecialConditionList(); // 归属公司
      this.getCourseUser(); // 课程方
      this.getTrainTypeList1();
      this.getEnterpriseTrainTypeList();
      if (this.$route.query.agencyId) {
        console.log(this.$route.query.trainTypeIds.split(","));
        if (this.$route.query.realRoleId) {
          this.ruleForm.realRoleId = this.$route.query.realRoleId;
        }
        this.ruleForm.roleId = this.$route.query.roleId;
        this.ruleForm4.agencyId = this.$route.query.agencyId;
        // this.ruleForm4.trainTypeId = this.$route.query.trainTypeIds.split(",");
        // this.getTrainTypeList(this.$route.query.agencyId);
        if ("add" == this.$route.query.stu) {
          this.oldRoleId = this.$route.query.roleId;
          this.getAgencyL(
            this.$route.query.agencyId,
            this.$route.query.agencyCooperationType
          );
        }
      }
      if (this.$route.query.contractId) {
        this.getContractInfo(this.$route.query.contractId)
      }
    },
    // 获取合同详情并赋值
    getContractInfo(contractId) {
      this.$post("/biz/new/bill/contract/getInfo", { contractId })
        .then((res) => {
          if (res.data.contractType == '10' || res.data.contractType == '30') {//收款
            this.ruleForm.fullname = res.data.partyAName//使用人姓名
            this.ruleForm.mobile = res.data.partyAMobile//使用人电话
            // this.compList = [{compId:res.data.signCompId,compName:res.data.signCompName}]//关联机构列表
            this.getCompany(res.data.signCompId)
            if (res.data.contractType == '10') {
              this.ruleForm2.trainTypeIds = res.data.trainTypeIdRange.split(",")//关联机构列表
            }
          } else {//付款 、代理
            this.ruleForm.fullname = res.data.partyBName
            this.ruleForm.mobile = res.data.partyBMobile
            if (res.data.contractType == '20') {
              this.getCompany(res.data.signCompId)
            }
            if (res.data.contractType == '40') {
              this.getAgency(res.data.agencyId, res.data.agencyType)
            }
          }
        })
    },
    getTrainTypeList1() {
      this.$post("/sys/category/train-type/tree")
        .then((res) => {
          this.baseTrainType = res.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    getEnterpriseTrainTypeList() {
      this.$post("/sys/category/train-type/enterprise")
        .then((res) => {
          this.enterpriseTrainType = res.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    //选择数据类型时，设置并调用禁用计算
    trainTypeListChange(val) {
      this.ruleForm2.trainTypeIds = val || [];
      this.resetTrainTypeDisable(true, this.trainTypeList2, []);
    },
    // 归属公司
    getspecialConditionList() {
      const list = this.$setDictionary("SPECIALCONDITION", "list");
      for (const key in list) {
        this.specialConditionList.push({
          id: key,
          name: list[key],
        });
      }
    },
    checkboxchangeIdcard(val) {
      this.ruleForm.adminSensitive = val;
    },
    checkboxchangeMobile(val) {
      this.ruleForm.mobileSensitive = val;
    },
    checkboxchangeWatermark(val) {
      this.ruleForm.videoIsMark = val;
    },
    checkboxchangeallowNew(val) {
      this.ruleForm4.allowNew = val;
    },
    /**
     * 新增
     */
    doAddSave() {
      const params = {
        adminName: this.ruleForm.adminName,
        fullname: this.ruleForm.fullname,
        mobile: this.ruleForm.mobile,
        realRoleId: this.ruleForm.realRoleId,
        roleId: this.ruleForm.roleId,
        adminSensitive: this.ruleForm.adminSensitive,
        mobileSensitive: this.ruleForm.mobileSensitive,
        videoIsMark: this.ruleForm.videoIsMark,
        allowNew: this.ruleForm4.allowNew,
        tempAccount: this.ruleForm.tempAccount,
      };
      if (this.ruleForm.specialCondition) {
        params.specialCondition = this.ruleForm.specialCondition;
      }
      if (this.ruleForm.fileList.length) {
        params.fileList = this.ruleForm.fileList;
      }
      if (this.ruleForm.tempAccount) {
        params.accountExpireDate = this.ruleForm.accountExpireDate;
      }
      if (this.ruleForm1.cityId) {
        params.areaId = this.ruleForm1.cityId;
      }
      if (this.ruleForm1.trainTypeId) {
        params.trainTypeId = this.ruleForm1.trainTypeId;
      }
      if (this.ruleForm1.superviseDate?.length === 2) {
        params.superviseStartDate = this.ruleForm1.superviseDate[0];
        params.superviseEndDate = this.ruleForm1.superviseDate[1];
      }
      if (this.ruleForm2.compId) {
        params.compId = this.ruleForm2.compId;
      }
      //企业内训账号和机构账号，均需设置 数据类型和账号权限
      if (this.ruleForm.roleId == "3" || this.ruleForm.roleId == '-6') {
        if (this.ruleForm2.permissionsId) {
          params.authorizationScope = this.ruleForm2.permissionsId;
        }
        if (this.ruleForm2.trainTypeIds) {
          params.trainTypeIdRange = this.ruleForm2.trainTypeIds.toString();
        }
      }
      if (this.ruleForm3.compId) {
        params.compId = this.ruleForm3.compId;
      }
      if (this.ruleForm4.agencyId) {
        params.compId = this.ruleForm4.agencyId;
      }
      if (this.ruleForm4.permissionsId) {
        params.authorizationScope = this.ruleForm4.permissionsId;
      }
      // if (this.ruleForm.roleId == "-3" || this.ruleForm.roleId == "-5") {
      //   if (this.ruleForm4.trainTypeId) {
      //     params.trainTypeIdRange = this.ruleForm4.trainTypeId.toString();
      //   }
      // }
      if (this.ruleForm.roleId == "-4") {
        if (this.ruleForm5.cityId) {
          params.areaId = this.ruleForm5.cityId;
        }
      }
      if (this.ruleForm.roleId == "-8") {
        if (this.ruleForm6.schoolId) {
          params.compId = this.ruleForm6.schoolId;
        }
      }
      if (this.ruleForm.roleId == "-9") {
        if (this.ruleForm7.compId) {
          params.compId = this.ruleForm7.compId;
        }
      }
      if (this.check()) {
        this.$post("/sys/admin/insert", params)
          .then((res) => {
            if (res.status == "0") {
              if (this.ruleForm2.compId) {
                this.$post("/biz/ct/company/checkCompanyIsTrain", { compId: this.ruleForm2.compId })
                  .then((ret) => {
                    if (ret.status == "0") {
                      if (ret.data) {
                        this.$message({
                          type: "success",
                          message: "账号创建成功，请设置机构参数！",
                        });
                        let compName = ''
                        this.compList.forEach((e, i) => {
                          if (e.compId == this.ruleForm2.compId) {
                            compName = e.compName
                          }
                        })
                        this.$router.push({
                          path: "/web/operate/appraisalOrganSetupUpdate",
                          query: {
                            stu: 'account',
                            compId: this.ruleForm2.compId,
                            compName
                          }
                        });
                      } else {
                        this.$message({
                          type: "success",
                          message: "创建成功",
                        });
                        this.dohandleOk();
                      }
                    }
                  })
                  .catch(() => {
                    return;
                  });
              } else {
                this.$message({
                  type: "success",
                  message: "创建成功",
                });
                this.dohandleOk();
              }
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    /**
     * 编辑
     */
    doEditSave() {
      const params = {
        adminName: this.ruleForm.adminName,
        fullname: this.ruleForm.fullname,
        mobile: this.ruleForm.mobile,
        realRoleId: this.ruleForm.realRoleId,
        roleId: this.ruleForm.roleId,
        adminId: this.id,
        adminSensitive: this.ruleForm.adminSensitive,
        mobileSensitive: this.ruleForm.mobileSensitive,
        videoIsMark: this.ruleForm.videoIsMark,
        allowNew: this.ruleForm4.allowNew,
        tempAccount: this.ruleForm.tempAccount,
      };
      if (this.ruleForm.tempAccount) {
        params.accountExpireDate = this.ruleForm.accountExpireDate;
      }
      if (this.ruleForm.specialCondition) {
        params.specialCondition = this.ruleForm.specialCondition;
      }
      if (this.ruleForm.fileList.length) {
        params.fileList = this.ruleForm.fileList;
      }
      if (this.ruleForm1.cityId) {
        params.areaId = this.ruleForm1.cityId;
      }
      if (this.ruleForm1.trainTypeId) {
        params.trainTypeId = this.ruleForm1.trainTypeId;
      }
      if (this.ruleForm1.superviseDate?.length === 2) {
        params.superviseStartDate = this.ruleForm1.superviseDate[0];
        params.superviseEndDate = this.ruleForm1.superviseDate[1];
      } else {
        params.superviseStartDate = "";
        params.superviseEndDate = "";
      }
      if (this.ruleForm2.compId) {
        params.compId = this.ruleForm2.compId;
      }
      //企业内训账号和机构账号，均需设置 数据类型和账号权限
      if (this.ruleForm.roleId == "3" || this.ruleForm.roleId == '-6') {
        if (this.ruleForm2.permissionsId) {
          params.authorizationScope = this.ruleForm2.permissionsId;
        }
        if (this.ruleForm2.trainTypeIds) {
          let arr = Array.from(new Set(this.ruleForm2.trainTypeIds));
          params.trainTypeIdRange = arr.toString();
        }
      }

      if (this.ruleForm3.compId) {
        params.compId = this.ruleForm3.compId;
      }
      if (this.ruleForm4.agencyId) {
        params.compId = this.ruleForm4.agencyId;
      }
      if (this.ruleForm4.permissionsId) {
        params.authorizationScope = this.ruleForm4.permissionsId;
      }
      // if (this.ruleForm.roleId == "-3" || this.ruleForm.roleId == "-5") {
      //   if (this.ruleForm4.trainTypeId) {
      //     params.trainTypeIdRange = this.ruleForm4.trainTypeId.toString();
      //   }
      // }
      if (this.ruleForm.roleId == "-4") {
        if (this.ruleForm5.cityId) {
          params.areaId = this.ruleForm5.cityId;
        }
      }
      if (this.ruleForm.roleId == "-8") {
        if (this.ruleForm6.schoolId) {
          params.compId = this.ruleForm6.schoolId;
        }
      }
      if (this.ruleForm.roleId == "-9") {
        if (this.ruleForm7.compId) {
          params.compId = this.ruleForm7.compId;
        }
      }
      if (this.check()) {
        this.$post("/sys/admin/modify", params)
          .then((res) => {
            if (res.status == "0") {
              if (this.ruleForm2.compId) {
                this.$post("/biz/ct/company/checkCompanyIsTrain", { compId: this.ruleForm2.compId })
                  .then((ret) => {
                    if (ret.status == "0") {
                      if (ret.data) {
                        this.$message({
                          type: "success",
                          message: "账号修改成功，请设置机构参数！",
                        });
                        let compName = ''
                        this.compList.forEach((e, i) => {
                          if (e.compId == this.ruleForm2.compId) {
                            compName = e.compName
                          }
                        })
                        this.$router.push({
                          path: "/web/operate/appraisalOrganSetupUpdate",
                          query: {
                            stu: 'account',
                            compId: this.ruleForm2.compId,
                            compName
                          }
                        });
                      } else {
                        this.$message({
                          type: "success",
                          message: "修改成功",
                        });
                        this.dohandleOk();
                      }
                    }
                  })
                  .catch(() => {
                    return;
                  });
              } else {
                this.$message({
                  type: "success",
                  message: "修改成功",
                });
                this.dohandleOk();
              }
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    /**
     * 编辑回显
     */
    getDetail(id) {
      this.$post("/sys/admin/adminId", { adminId: id }).then((res) => {
        let accountExpireDate = res.data.accountExpireDate
          ? res.data.accountExpireDate.replaceAll("/", "-")
          : "";
        this.ruleForm = {
          ...res.data,
          fileList: res.data.fileList || [],
          accountExpireDate: accountExpireDate,
        };
        this.oldRoleId = res.data.roleId;
        if (res.data.trainTypeId) {
          this.ruleForm1.trainTypeId = res.data.trainTypeId;
        }
        if (res.data.areaId) {
          this.ruleForm1.cityId = res.data.areaId;
        }
        if (res.data.superviseStartDate && res.data.superviseEndDate) {
          this.ruleForm1.superviseDate = [res.data.superviseStartDate.replaceAll("/", "-"), res.data.superviseEndDate.replaceAll("/", "-")];
        }
        if (res.data.compId) {
          if (res.data.roleId == -2) {
            this.ruleForm3.compId = res.data.compId;
            this.getCourseUserView(res.data.compId);
          } else if (res.data.roleId == "-3" || res.data.roleId == "-5") {
            this.ruleForm4.agencyId = res.data.compId;
            // this.ruleForm4.trainTypeId = res.data.trainTypeIdRange.split(",");
            this.ruleForm4.permissionsId = res.data.authorizationScope;
            this.ruleForm4.allowNew = res.data.allowNew;
            let agencyCooperationType = "10";
            if (res.data.roleId == "-5") {
              agencyCooperationType = "20";
            }
            this.getAgencyL(res.data.compId, agencyCooperationType);
            // this.getTrainTypeList(res.data.compId); // 数据类型
          } else if (res.data.roleId == "-8") {
            this.ruleForm6.schoolId = res.data.compId;
            this.getSchoolListId(res.data.compId);
          } else if (res.data.roleId == "-9") {
            this.ruleForm7.compId = res.data.compId;
            this.getCompany(res.data.compId);
          } else if (res.data.roleId == "-4") {
            this.ruleForm5.cityId = res.data.areaId;
          } else {
            //当前剩余机构和企业内训 (3和-6)
            this.ruleForm2.compId = res.data.compId;
            this.ruleForm2.trainTypeIds = res.data.trainTypeIdRange.split(",");
            this.ruleForm2.permissionsId = res.data.authorizationScope;
            this.getCompany(res.data.compId);
            //企业内训和机构的范围不同
            if (res.data.roleId == -6) {
              this.trainTypeList2 = this.enterpriseTrainType;
            } else {
              this.trainTypeList2 = this.baseTrainType;
            }
            //加载数据后调用数据类型禁用重算
            this.resetTrainTypeDisable(true, this.trainTypeList2, []);
          }
        }
      });
    },
    /**
     * 课程方
     */
    getCourseUser() {
      this.$post("/biz/ec/course/owner/list", {}, 3000, true, 2).then((res) => {
        this.courseUserList = res.data;
      });
    },
    getCourseUserView(courseOwnerId) {
      this.$post(
        "/biz/ec/course/owner/list",
        { courseOwnerId },
        3000,
        true,
        2
      ).then((res) => {
        this.courseUserList = res.data;
      });
    },
    /**
     * 课程方
     */
    searchCourseUser(query) {
      if (query.trim().length > 0) {
        this.$post(
          "/biz/ec/course/owner/list",
          { name: query },
          3000,
          true,
          2
        ).then((res) => {
          this.courseUserList = res.data;
        });
      } else {
        this.courseUserList = [];
      }
    },
    /**
     * 代理商
     */
    // agencyChange(val) {
    //   console.log(val);
    //   this.getTrainTypeList(val); // 数据类型
    // },
    getAgency(agencyId, agencyType) {
      this.$post("/biz/new/bill/contract/agency/id", { agencyId, agencyType }).then((res) => {
        this.AgencyList = [{
          agencyId: res.data.agencyId,
          agencyName: res.data.compName,
        }];
      });
    },
    searchAgency(query) {
      if (query.trim().length > 0) {
        let agencyCooperationType = "10";
        if (this.ruleForm.roleId == -5) {
          agencyCooperationType = "20";
        }
        this.$post("/biz/mercantile/agency/searchList", {
          agencyName: query,
          agencyCooperationType: agencyCooperationType,
        }).then((res) => {
          this.AgencyList = res.data;
        });
      } else {
        this.AgencyList = [];
      }
    },
    getAgencyL(agencyId, agencyCooperationType) {
      this.$post("/biz/mercantile/agency/searchList", {
        agencyId: agencyId,
        agencyCooperationType: agencyCooperationType,
      }).then((res) => {
        this.AgencyList = res.data || [];
      });
    },
    // permissionsChange(val) {
    //   console.log(val);
    //   if (val == 10) {
    //     this.trainTypeList1.map((el) => {
    //       console.log(el.id);
    //       this.ruleForm4.trainTypeId.push(el.id);
    //     });
    //   }
    // },
    /**
     * 角色字段点击
     */
    roleChange(val) {
      let currentRoleId = this.rolelist.find((i) => i.id == val).parentId;
      this.ruleForm1.trainTypeId = "";
      this.ruleForm1.areaId = "";
      this.ruleForm1.superviseDate = [];
      this.ruleForm2.compId = "";
      this.ruleForm2.permissionsId = 20;
      // this.ruleForm3.compId = "";
      if (currentRoleId != this.oldRoleId) {
        this.ruleForm4.agencyId = "";
        if (!this.$route.query.contractId) {
          this.AgencyList = [];
        }
        this.ruleForm4.permissionsId = "";
        // this.ruleForm4.trainTypeId = "";
        this.ruleForm4.allowNew = "";
        this.oldRoleId = currentRoleId;
        //根据角色类型显示对应 数据类型集合 -6 : 企业内训
        if (-6 == currentRoleId) {
          this.trainTypeList2 = this.enterpriseTrainType;
        } else {
          this.trainTypeList2 = this.baseTrainType;
        }
        if (!this.$route.query.contractId) {
          //切换角色，默认选择全部培训类型
          this.ruleForm2.trainTypeIds = [];
          this.trainTypeList2.map(o => {
            this.ruleForm2.trainTypeIds.push(o.id);
          })
        }
        //修改角色后， 调用数据类型禁用重算
        this.resetTrainTypeDisable(true, this.trainTypeList2, []);
      }
      this.ruleForm.roleId = currentRoleId;
    },
    /**
     * 重新计算数据类型的禁用状态
     */
    resetTrainTypeDisable(isRoot, obj, idTree) {
      //若树的根数量在1个节点以下，不调整禁用状态
      if (isRoot && obj.length <= 1) {
        return;
      }
      obj.map(o => {
        //一级树清空节点集合
        if (isRoot) {
          idTree = [];
        }
        //非一级树时，判断节点集合中的节点是否在 勾选集合内，在的话禁用状态为true;否则为false
        let disableStatus = false;
        if (!isRoot) {
          idTree.map(t => {
            if (this.ruleForm2.trainTypeIds.includes(t)) {
              disableStatus = true;
            }
          })
        }
        //设置当前节点禁用状态
        o.disabled = disableStatus;
        //塞入当前节点
        idTree.push(o.id);
        //存在子节点则继续下钻计算禁用状态    
        if (o.children) {
          this.resetTrainTypeDisable(false, o.children, idTree);
        }
        //弹出当前节点
        idTree.pop(o.id);
      })
    },
    /**
     * 查询角色列表 /sys/per/role/list
     */
    getrole(val) {
      this.$post("/sys/per/role/basal")
        .then((res) => {
          this.roleType = res.data || [];
          this.getRoleList(val); // 角色类型
        })
        .catch(() => {
          return;
        });
    },
    // 获取 - 学校列表（当角色类型是-8实习学校）- name获取
    getSchoolList(query) {
      if (query.trim().length > 0) {
        this.$post("/biz/intern/school/choice/list", {
          schoolName: query,
        })
          .then((res) => {
            this.schoolListData = res.data || [];
          })
          .catch(() => {
            return;
          });
      } else {
        this.schoolListData = [];
      }
    },
    // 获取 - 学校列表（当角色类型是-8实习学校）- id获取
    getSchoolListId(query) {
      if (query.trim().length > 0) {
        this.$post("/biz/intern/school/info", {
          schoolId: query,
        })
          .then((res) => {
            this.schoolListData = res.data ? [res.data] : [];
          })
          .catch(() => {
            return;
          });
      } else {
        this.schoolListData = [];
      }
    },
    getRoleList(roleId) {
      let param = {};
      if (roleId) {
        param.roleId = roleId;
      }
      this.$post("/sys/per/role/limit/list", param)
        .then((res) => {
          const data = res.data || [];
          data.map((i) => {
            i.name = i.name + "(" + this.getRoleTypeName(i.parentId) + ")";
          });
          this.rolelist = res.data;
        })
        .catch(() => {
          return;
        });
    },
    getRoleTypeName(val) {
      return this.roleType.find((i) => i.id == val).name;
    },
    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.cityTree = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    handleCascader(val) {
      console.log(val);
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.compList = [res.data];
      });
    },
    handleTrainType(val) {
      console.log(val);
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    /**
     * 关联单位
     */
    searchCopmp(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/likeCompName", { 
          compName: query,
          compType: ['-10'].includes(this.ruleForm.roleId) ? '60':''
        }).then(
          (res) => {
            this.compList = res.data;
          }
        );
      } else {
        this.compList = [];
      }
    },
    check() {
      let stu = false;
      const form = this.$refs.ruleForm;
      const form1 = this.$refs.ruleForm1;
      const form2 = this.$refs.ruleForm2;
      const form3 = this.$refs.ruleForm3;
      const form4 = this.$refs.ruleForm4;
      const form5 = this.$refs.ruleForm5;
      const form6 = this.$refs.ruleForm6;
      const form7 = this.$refs.ruleForm7;
      form.validate((valid) => {
        if (valid) {
          if (this.ruleForm.roleId == 1 || this.ruleForm.roleId == -1) {
            stu = true;
            if (form1) {
              form1.clearValidate();
            }
            if (form2) {
              form2.clearValidate();
            }
            if (form3) {
              form3.clearValidate();
            }
            this.ruleForm1 = {
              cityId: "", // 行政区划id
              trainTypeId: "", // 培训类型
              superviseDate: [],//数据范围
            };
            this.ruleForm2 = {
              compId: "", // 单位
              trainTypeIds: [], // 培训类型
            };
            this.ruleForm3 = {
              compId: "", // 课程方
            };
          } else if (this.ruleForm.roleId == 2) {
            form1.validate((valid1) => {
              if (valid1) {
                stu = true;
                if (form2) {
                  form2.clearValidate();
                }
                if (form3) {
                  form3.clearValidate();
                }
                this.ruleForm2 = {
                  compId: "", // 单位
                  trainTypeId: [], // 培训类型
                };
                this.ruleForm3 = {
                  compId: "", // 课程方
                };
              } else {
                stu = false;
              }
            });
          } else if (this.ruleForm.roleId == 3 || this.ruleForm.roleId == -6 || this.ruleForm.roleId == -10) {
            form2.validate((valid2) => {
              if (valid2) {
                stu = true;

                if (form1) {
                  form1.clearValidate();
                }
                if (form3) {
                  form3.clearValidate();
                }
                this.ruleForm1 = {
                  cityId: "", // 行政区划id
                  trainTypeId: "", // 培训类型
                  superviseDate: [],//数据范围
                };
                this.ruleForm3 = {
                  compId: "", // 课程方
                };
              } else {
                stu = false;
              }
            });
          } else if (this.ruleForm.roleId == -2) {
            form3.validate((valid3) => {
              if (valid3) {
                stu = true;
                if (form1) {
                  form1.clearValidate();
                }
                if (form2) {
                  form2.clearValidate();
                }
                this.ruleForm1 = {
                  cityId: "", // 行政区划id
                  trainTypeId: "", // 培训类型
                  superviseDate: [],//数据范围
                };
                this.ruleForm2 = {
                  compId: "", // 单位
                  trainTypeIds: [], // 培训类型
                };
              } else {
                stu = false;
              }
            });
          } else if (this.ruleForm.roleId == -3) {
            form4.validate((valid4) => {
              if (valid4) {
                stu = true;
                if (form1) {
                  form1.clearValidate();
                }
                if (form2) {
                  form2.clearValidate();
                }
                if (form3) {
                  form3.clearValidate();
                }
                this.ruleForm1 = {
                  cityId: "", // 行政区划id
                  trainTypeId: "", // 培训类型
                  superviseDate: [],//数据范围
                };
                this.ruleForm2 = {
                  compId: "", // 单位
                  trainTypeIds: [], // 培训类型
                };
                this.ruleForm3 = {
                  compId: "", // 单位
                };
              } else {
                stu = false;
              }
            });
          } else if (this.ruleForm.roleId == -4) {
            form5.validate((valid4) => {
              if (valid4) {
                stu = true;
                if (form1) {
                  form1.clearValidate();
                }
                if (form2) {
                  form2.clearValidate();
                }
                if (form3) {
                  form3.clearValidate();
                }
                if (form4) {
                  form4.clearValidate();
                }
                this.ruleForm1 = {
                  cityId: "", // 行政区划id
                  trainTypeId: "", // 培训类型
                  superviseDate: [],//数据范围
                };
                this.ruleForm2 = {
                  compId: "", // 单位
                  trainTypeIds: [], // 培训类型
                };
                this.ruleForm3 = {
                  compId: "", // 单位
                };
                this.ruleForm4 = {
                  agencyId: "", // 代理商
                  trainTypeId: [],
                  permissionsId: "",
                  allowNew: false,
                };
              } else {
                stu = false;
              }
            });
          } else if (this.ruleForm.roleId == -5) {
            form4.validate((valid4) => {
              if (valid4) {
                stu = true;
                if (form1) {
                  form1.clearValidate();
                }
                if (form2) {
                  form2.clearValidate();
                }
                if (form3) {
                  form3.clearValidate();
                }
                if (form4) {
                  form4.clearValidate();
                }
                this.ruleForm1 = {
                  cityId: "", // 行政区划id
                  trainTypeId: "", // 培训类型
                  superviseDate: [],//数据范围
                };
                this.ruleForm2 = {
                  compId: "", // 单位
                  trainTypeIds: [], // 培训类型
                };
                this.ruleForm3 = {
                  compId: "", // 单位
                };
              } else {
                stu = false;
              }
            });
          } else if (this.ruleForm.roleId == -8) {
            form6.validate((valid6) => {
              if (valid6) {
                stu = true;
                if (form1) {
                  form1.clearValidate();
                }
                if (form2) {
                  form2.clearValidate();
                }
                if (form3) {
                  form3.clearValidate();
                }
                if (form4) {
                  form4.clearValidate();
                }
                if (form5) {
                  form5.clearValidate();
                }
              } else {
                stu = false;
              }
            });
          } else if (this.ruleForm.roleId == -9) {
            form7.validate((valid7) => {
              if (valid7) {
                stu = true;
                if (form1) {
                  form1.clearValidate();
                }
                if (form2) {
                  form2.clearValidate();
                }
                if (form3) {
                  form3.clearValidate();
                }
                if (form4) {
                  form4.clearValidate();
                }
                if (form5) {
                  form5.clearValidate();
                }
                if (form6) {
                  form6.clearValidate();
                }
              } else {
                stu = false;
              }
            });
          }
        } else {
          stu = false;
          this.$message.error("校验未通过");
        }
      });
      return stu;
    },
    /**
     * 返回
     */
    dohandleOk() {
      setTimeout(() => {
        this.$router.push({
          path: "/web/operate/accountList",
          query: {
            refrsh: true,
          },
        });
      }, 300);
    },
    //上传文件
    uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.ruleForm.fileList.push({
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileType: extension,
            fileUrl: result.data.fileURL,
          });
        })
        .catch((err) => {
          return;
        });
    },
    reomveExlUpload(index) {
      this.ruleForm.fileList.splice(index, 1);
      this.$forceUpdate();
    },
    lookExl(item) {
      console.log(item.fileUrl);
      this.previewLoding1 = true;
      this.fileType = item.fileType;
      console.log("item.fileType", item.fileType);
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        console.log(1);
        setTimeout(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        }, 300);
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.password {
  .el-form-item__content {
    line-height: 20px;
  }
}


// .el-select-dropdown__item {
//     height: 69px !important;
//     min-height:34px !important;
//   }</style>
